import React, { useEffect, useState } from "react";
import { NavLogo } from "../assets/svg";
import {
  EthereumIcon,
  CopyIcon,
  LoadingIcon,
  CheckIcon,
  FailIcon,
} from "../assets/svg";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Loading from "../components/Loading/Loading";

export default function DetailPayment() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState({});
  const [time, setTime] = useState({
    minute: 0,
    second: 0,
  });
  let [count, setCount] = useState(1);
  // const [isLoading, setisLoading] = useState(true);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  const getApi = async () => {
    try {
      let data2 = await fetch(`https://w3-api.0xnumber.io/checkOrder/${id}`, {
        method: "POST",
      });
      data2 = await data2.json();
      setData(data2);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    setTime({
      minute: Math.floor(data?.expired_in_seconds / 60),
      second: (
        data?.expired_in_seconds -
        Math.floor(data?.expired_in_seconds / 60) * 60
      ).toFixed(0),
    });

    let interval;
    if (data?.deposited === true) {
      interval = setInterval(() => {
        setCount((prev) => {
          let num = prev - 1;
          // if (num === 0) {
          //   navigate("/");
          // }
          return num;
        });
      }, [1000]);
    }

    return () => {
      clearInterval(interval);
    };
  }, [data]);

  useEffect(() => {
    getApi();
  }, []);

  useEffect(() => {
    if (data.status !== "success" && data.status !== "expired") {
      setTimeout(async () => {
        getApi();
      }, 3000);
    } else {
      if (data.status === "success") {
        setTimeout(() => {
          window.location.href = `https://dapp.0xnumber.io?orderId=${id}`;
        }, 2000);
      }
    }
  }, [id, data]);

  if (Object.keys(data).length === 0) {
    return <Loading></Loading>;
  }
  return (
    <div className="!bg-[#000000] min-h-[100vh] min-w-[100vw] flex items-center justify-center">
      <div className="p-8 bg-[#0E0F12] md:w-[768px] w-full border-solid border-2 border-[#1E1E1E] rounded-[10px] flex flex-col items-center justify-center gap-4">
        <NavLogo />
        <h2 className="text-[25px] font-semibold my-4 text-center">
          Send ETH to the wallet address by copying it or scan the following QR
          code:
        </h2>
        <form className="grid gap-8 w-full gridLayout">
          <div className="w-full flex flex-col gap-3 justify-between">
            <div className="flex flex-col gap-2">
              <p>Check Coin Ticker</p>
              <div className="bg-[#1e1e1e] p-2 px-3 flex gap-2 items-center rounded-[10px]">
                <EthereumIcon width="40"></EthereumIcon> Ethereum
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <p>Check the Total in ETH</p>
              <div className="bg-[#1E1E1E] p-2 px-4 rounded-[10px] flex justify-between items-center">
                {data?.value} ETH{" "}
                <CopyIcon
                  className="text-[#AA4406] cursor-pointer"
                  onClick={() => copyToClipboard(data?.value)}
                />
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <p>Send the above amount to this wallet address</p>
              <div className="bg-[#1E1E1E] p-2 px-4 rounded-[10px] flex justify-between items-center">
                {data?.destination}{" "}
                <CopyIcon
                  className="text-[#AA4406] cursor-pointer"
                  onClick={() => copyToClipboard(data?.destination)}
                />
              </div>
            </div>
          </div>
          <div className="rounded-[10px] p-3 w-full min-h-10 text-center flex flex-col items-center gap-2 justify-center bg-[#1e1e1e]">
            <img src={data?.destination_qr} alt="" />
            <p>
              this asddress is valid for the specified period only: <br />
              <span className="text-red-400">
                00:{time?.minute < 10 ? "0" + time?.minute : time?.minute}:
                {time?.second < 10 ? "0" + time?.second : time?.second}
              </span>
            </p>
          </div>
        </form>
        {/* <h2 className="text-[20px] font-semibold mt-10 text-center">
          Or Pay with Web3 Wallet
        </h2>
        <button className="--border-gradient1 py-2 px-10 text-[20px] text-white rounded-[10px]">
          Pay Now
        </button> */}
        <div className="flex gap-4 items-center justify-center">
          {/* <div className="flex flex-col items-center justify-center gap-2">
            <LoadingIcon className="animate-spin text-white"></LoadingIcon>
            <h2 className="text-xl text-center">
              Your Transaction is Processing
            </h2>
          </div> */}
          {data?.status === "success" && (
            <div className="flex flex-col items-center justify-center gap-2">
              <CheckIcon className="text-[#93fa89]"></CheckIcon>
              <h2 className="text-xl text-center">Your Transaction Success</h2>
            </div>
          )}

          {data?.status === "expired" && (
            <div className="flex flex-col items-center justify-center gap-2">
              <FailIcon className="text-[#F87171]"></FailIcon>
              <h2 className="text-xl text-center">Your Transaction Expired</h2>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
