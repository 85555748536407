import React from "react";
import "./Loading.css";
import Lottie from "lottie-react";
import LoadJson from "./0xn.json";

function Loading() {
  return (
    <div className="h-screen flex items-center justify-center">
      <Lottie className="h-[200px]" animationData={LoadJson} loop={true} />
    </div>
  );
}

export default Loading;
