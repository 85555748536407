import React from "react";
import Details from "../Pages/DetailPayment";
import NotFound from "../components/404/NotFound";
import Loading from "../components/Loading/Loading";

const { createBrowserRouter } = require("react-router-dom");

export const routerList = createBrowserRouter([
  {
    path: "*",
    element: <NotFound />,
  },
  {
    path: "load",
    element: <Loading />,
  },

  {
    path: "/order/:id",
    element: (
      <React.Suspense fallback={<Loading />}>
        <Details />
      </React.Suspense>
    )
  },
]);
