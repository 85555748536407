import React from "react";
import "./styles/App.css";
import { RouterProvider } from "react-router-dom";
import { routerList } from "./utils/router";

function App() {
  return (
    <>
      <RouterProvider router={routerList} />
    </>
  );
}

export default App;
